import React from 'react';
import Layout from '../components/layout';

const airplaneMuseuem = () => (
  <Layout>
    <h2>Airplane Museum</h2>
  </Layout>
);

export default airplaneMuseuem;
