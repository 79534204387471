import React from 'react';
import { Global, css } from '@emotion/react';
import Helmet from 'react-helmet';
import Header from '../components/header';
import useSiteMetadata from '../hooks/use-sitemetadata';
import NavList from '../components/navlist';

const Layout = ({ children }) => {
  const { title, description } = useSiteMetadata();

  return (
    <>
      <Global
        styles={css`
        * {
          box-sizing: border-box;
          margin: 0;
          padding: 0;
        }

        * + * {
          margin-top: 1rem;
        }

        html,
        body {
          background: #000;
          margin: 0;
          color: #bbb;
          font-family: -apple-system, "Segoe UI", Roboto, Helvetica, Arial,
            sans-serif;
          font-size: 18px;
          line-height: 1.4;

          /* remove margin for the main div that Gatsby mounts into */
          > div {
            margin-top: 0;
          }

          h1,
          h2,
          h3,
          h4,
          h5,
          h6 {
            color: #eee;
            line-height: 1.3rem;

            + * {
              margin-top: 0.5rem;
            }
          }
          h1 {
            line-height: 1.8rem;
          }

          strong {
            color: #eee;
          }

          li {
            margin-top: 0.25rem;
          }
        }

        footer,
        header {
          width: 100%;
          background: #eee;

          > h1 {
          margin: 0;
        }
      `}
      />
      <Helmet>
        <html lang="en" />
        <title>{title}</title>
        <meta name="description" content={description} />
      </Helmet>
      <Header />
      <main
        css={css`
          margin: 4rem auto 4rem;
          max-width: 90vw;
          width: 90vw;
        `}
      >
        <h2>This is components/layout.js</h2>
        {children}
        <NavList />
      </main>
    </>
  );
};

export default Layout;
