import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Link } from 'gatsby';
import Icon from '../svg/MasumiHayashiFineArt.svg';

const NavLink = styled(Link)`
  color: #eee;
  font-size: 1rem;
  font-weight: ${(props) => props.fontWeight || 'normal'};
  line-height: 1;
  margin: 0 0.5rem 0 0;
  padding 0.25rem;
  text-decoration: none;
  background-image: none;

  &.current-page {
    border-bottom: 2px solid #222;
  }

  &:last-of-type {
    margin-right:0;
  }

`;

const Header = (props) => (
  <header
    css={css`
      /* background: #222; */
      color: #ddd;
      fontweight: 3rem;
      border-bottom: 1px solid #ddd;
      display: inline-block;
      justify-content: space-between;
      padding: 0.5rem calc((100vw - 900px) / 2);
      top: 0;
      width: 100%;
      z-index: 2;
    `}
  >
    <NavLink to="/" activeClassName="current-page" fontWeight="bold">
      /* Masumi Hayashi Fine Art */
    </NavLink>

    <nav
      css={css`
        margin-top: 0;
      `}
    ></nav>
  </header>
);

export default Header;
