import React from 'react';
import { css } from '@emotion/react';
import { Link, navigate } from 'gatsby';
import NavLink from '../components/navlink';
import useArtworks from '../hooks/use-artworks';
import './navlist.module.css';
// import useSacredArchitectures from '../hooks/use-sacred-architectures';

const NavList = () => {
  const artworks = useArtworks();
  // const sacredArchitectures = useSacredArchitectures();
  // console.log(artworks);
  const sacredArch = artworks.filter(
    (art) => art.frontmatter.series == 'Sacred Architectures'
  );
  const indiaSacredArch = sacredArch.filter(
    (art) => art.frontmatter.country == 'India'
  );
  const nepalSacredArch = sacredArch.filter(
    (art) => art.frontmatter.country == 'Nepal'
  );
  const cambodiaSacredArch = sacredArch.filter(
    (art) => art.frontmatter.country == 'Cambodia'
  );
  const thaiSacredArch = sacredArch.filter(
    (art) => art.frontmatter.country == 'Thailand'
  );
  const japanSacredArch = sacredArch.filter(
    (art) => art.frontmatter.country == 'Japan'
  );

  const abandonedPrisons = artworks.filter(
    (art) => art.frontmatter.series == 'Abandoned Prisons'
  );
  const interneePortraits = artworks.filter(
    (art) => art.frontmatter.series == 'Internee Portraits'
  );
  const cityWorks = artworks.filter(
    (art) => art.frontmatter.series == 'City Works'
  );
  const commissions = artworks.filter(
    (art) => art.frontmatter.series == 'Commissions'
  );
  const epaSuperfundSites = artworks.filter(
    (art) => art.frontmatter.series == 'EPA Superfund Sites'
  );
  const internmentCamps = artworks.filter(
    (art) => art.frontmatter.series == 'Japanese-American Internment Camps'
  );
  const misc = artworks.filter(
    (art) => art.frontmatter.series == 'Miscellaneous'
  );
  const postIndustrial = artworks.filter(
    (art) => art.frontmatter.series == 'Post-Industrial Landscapes'
  );
  const warMilitary = artworks.filter(
    (art) => art.frontmatter.series == 'War and Military'
  );
  // console.log(
  // sacredArch,
  // indiaSacredArch,
  // nepalSacredArch,
  // cambodiaSacredArch,
  // thaiSacredArch,
  // japanSacredArch
  // );
  // console.log(abandonedPrisons);
  // console.log(interneePortraits);
  // console.log(cityWorks);
  // console.log(commissions);
  // console.log(epaSuperfundSites);
  // console.log(epaSuperfundSites);
  // console.log(misc);
  // console.log(postIndustrial);
  // console.log(warMilitary);

  const artlinks = artworks.map((art) => {
    return (
      <li key={art.frontmatter.name}>
        <NavLink to={`/${art.frontmatter.slug}`}>
          {art.frontmatter.title}, {art.frontmatter.year}
        </NavLink>
      </li>
    );
  });

  const buildArtLinks = (series) => {
    let seriesLinks = series.map((art) => {
      return (
        <li key={art.frontmatter.name}>
          <NavLink to={`/${art.frontmatter.slug}`}>
            {art.frontmatter.title}, {art.frontmatter.year}
          </NavLink>
        </li>
      );
    });
    return seriesLinks;
  };

  return (
    <nav>
      <h4>Sacred Architectures</h4>
      <h5>India:</h5>
      <ul className="navlistUL">{buildArtLinks(indiaSacredArch)}</ul>

      <h5>Nepal:</h5>
      <ul className="navlistUL">{buildArtLinks(nepalSacredArch)}</ul>

      <h5>Cambodia:</h5>
      <ul className="navlistUL">{buildArtLinks(cambodiaSacredArch)}</ul>

      <h5>Thailand:</h5>
      <ul className="navlistUL">{buildArtLinks(thaiSacredArch)}</ul>

      <h5>Japan:</h5>
      <ul className="navlistUL">{buildArtLinks(japanSacredArch)}</ul>

      <h5>Japanese-American Internment Camp:</h5>
      <ul className="navlistUL">{buildArtLinks(internmentCamps)}</ul>

      <h5>Internee Portraits:</h5>
      <ul className="navlistUL">{buildArtLinks(interneePortraits)}</ul>

      <h5>EPA Superfund Sites:</h5>
      <ul className="navlistUL">{buildArtLinks(epaSuperfundSites)}</ul>

      <h5>Abandoned Prisons:</h5>
      <ul className="navlistUL">{buildArtLinks(abandonedPrisons)}</ul>

      <h5>City Works:</h5>
      <ul className="navlistUL">{buildArtLinks(cityWorks)}</ul>

      <h5>Post-Industrial Landscapes:</h5>
      <ul className="navlistUL">{buildArtLinks(postIndustrial)}</ul>

      <h5>Miscellaneous:</h5>
      <ul className="navlistUL">{buildArtLinks(misc)}</ul>

      <h5>War and Military Sites:</h5>
      <ul className="navlistUL">{buildArtLinks(warMilitary)}</ul>

      <h5>Commissions:</h5>
      <ul className="navlistUL">{buildArtLinks(commissions)}</ul>
    </nav>
  );
};

export default NavList;
