import { graphql, useStaticQuery } from 'gatsby';

const useArtworks = () => {
  const data = useStaticQuery(graphql`
    query {
      allMdx(sort: { fields: [frontmatter___name], order: ASC }) {
        nodes {
          frontmatter {
            slug
            name
            title
            series
            media
            size
            year
            city
            state
            country
            inventory
          }
        }
      }
    }
  `);

  return data.allMdx.nodes;
};

export default useArtworks;
